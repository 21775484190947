import axios from 'axios'

export const key = "b87214a772f463f617283ddebc13c33c82e62942"

const api = axios.create({
    baseURL: 'https://api-ssl.bitly.com/v4/',
    headers:{
        'Authorization': `Bearer ${key}`
    }
})

export default api